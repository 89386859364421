import React from 'react'


const WhyChoose = () => {


  return (
    <section className="choosedesire bep20 ychoose pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="bluecolor">Why Choose Coinsclone </span>for BEP20 Token Development? </h2>
            <p className="pharagraph head">As a reputed BEP20 token development company, Coinsclone excels in offering feature-backed and future-ready BEP20 Binance tokens. With contemporary development procedures, we customize the Binance token functionalities making them ideal for serving different use cases. Along with creating a BEP20 token as future-ready, Coinsclone has been the pioneer in this industry with the following attributes.
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/expertise.png" alt="Expertise" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Expertise
                </h3>
                <p className="pharagraph">
                We are a well-experienced team of BEP20 token development experts bringing thriving businesses into the crypto industry. With unparalleled expertise and deeper technical fluency, we create customized Binance tokens that fulfill your demands and requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3">
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/experience.png" alt="Experience" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Experience
                </h3>
                <p className="pharagraph">
                Coinsclone is a well-known BEP20 token development company serving the industry with 7+ years of experience. With a divine understanding of Binance’s multi-chain architecture, we develop BEP20 tokens to perfectly meet the client’s business idea.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/robust-security-measures.png" alt="Robust Security Measures" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Robust Security Measures
                </h3>
                <p className="pharagraph">
                Our pioneer BEP20 token creation services prioritize robust security as a top priority. With refined smart contract functionalities, our BSC tokens are meant for secure transactions and are authenticated with higher-end encryption.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/quality-assessment.png" alt="Quality Assessment" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Quality Assessment
                </h3>
                <p className="pharagraph">
                We follow refined quality assessment procedures while deploying the BEP20 tokens. So you are in full control of quality and capable of maintaining your business promises while minimizing the real-time challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/diverse-range.png" alt="Diverse Range of Talent" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Diverse Range of Talent
                </h3>
                <p className="pharagraph">
                Supercharge your BEP20 Binance tokens with our diverse range of talented professionals. With a thriving portfolio and successful BEP20 projects, Coinsclone leads the market competition leveraging the ultra edges of BSC networks.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/faster-delivery.png" alt="Faster Delivery" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Faster Delivery
                </h3>
                <p className="pharagraph">
                Through wider experience and enriched technical capability, we develop BEP20 tokens and deliver the tokens extremely fast, greatly efficient, and perfectly customized to meet your business growth and profit potential.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/wallet-compatibility.png" alt="Wallet Compatibility" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Wallet Compatibility
                </h3>
                <p className="pharagraph">
                We create BEP20 tokens that are compatible with all contemporary and popularly used non-custodial crypto wallets. So the user can easily send and receive these Binance tokens across multiple crypto wallets.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock nothover">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/technical-support.png" alt="Technical Support" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Technical Support
                </h3>
                <p className="pharagraph">
                Coinsclone offers 24/7 technical support to upgrade and maintain BEP20 BSC token features up to industry standards. We help you resolve real-time challenges instantly without compromising on seamless token transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose